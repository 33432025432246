import React, {createContext, useState, useContext} from 'react'
import AlertsComponent from '../../components/utils/alerts';

const AlertContext = createContext();

export const useAlertContext = () => {
    return useContext(AlertContext);
}

export default function AlertProvider({children}) {
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState();
    const [alertMessage, setAlertMessage] = useState('');
    const [alertTitle, setAlertTitle] = useState('')

    return (
        <AlertContext.Provider value={{
            open,
            setOpen,
            severity,
            setSeverity,
            alertMessage,
            setAlertMessage,
            alertTitle,
            setAlertTitle
        }}>
            {children}
            <AlertsComponent />
        </AlertContext.Provider>
    )
}
export const handleError = (err, action, alertInterface) => {
    switch (action) {
        case "Alert":
            const { setAlertTitle, setSeverity, setAlertMessage, setOpen } = alertInterface;

            if (err.response && err.response.status < 500) {
                setAlertTitle("")
                setSeverity("error")
                setAlertMessage(err.response.data.message);
                setOpen(true);
            } else {
                setAlertTitle("Network Error")
                setSeverity("error")
                setAlertMessage("Thank you for your patience. Our team is working to fix this.");
                setOpen(true);
            }
            break;
        case "Form":
            if (err.response && err.response.status < 500) {
                alertInterface(err.response.data.message)
            } else {
                window.alert("Network Error: Thank you for your patience. Our team is working to fix this.")
            }
            break;
        default:
            if (err.response && err.response.status < 500) {
            } else {
                window.alert("Network Error: Thank you for your patience. Our team is working to fix this.")
            }
            break;
    }
}